<template>
  <!-- 质检类型 -->
  <div class="controller-type">
    <!-- 筛选区域 -->
    <div class="screening">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form
              :inline="true"
              :model="searchForm"
              class="demo-form-inline"
            >
              <!-- 筛选项 -->
              <el-row>
                <el-col :span="24">
                  <el-row :gutter="12">
                    <el-col :span="22">
                      <el-form-item label="类型名称：" prop="">
                        <el-input v-model="searchForm.name" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="关键字串：" prop="">
                        <el-input
                          v-model="searchForm.keywords"
                          clearable
                          size="mini"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="默认规则：" prop="">
                        <el-select
                          v-model="searchForm.isDefault"
                          placeholder="请选择"
                          style="width: 100%"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['非默认', '默认']"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="状&emsp;态&emsp;:" prop="">
                        <el-select
                          v-model="searchForm.status"
                          placeholder="请选择"
                          style="width: 100%"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['启用', '禁用']"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2">
                      <div class="title-buttom">
                        <el-button class="cancel" size="small" @click="searchTapReset()" round>重置</el-button>
                        <el-button type="primary" size="small" class="confirmAdd" @click="searchListFun()" round>查询</el-button>
                      </div>
                    </el-col>
                    
                  </el-row>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="risk-button">
          <span>风险等级：</span>
          <el-radio-group v-model="searchForm.risk" @change="queryList"  fill="#637DFF">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button :label="3">高</el-radio-button>
            <el-radio-button :label="2">中</el-radio-button>
            <el-radio-button :label="1">低</el-radio-button>
            <el-radio-button :label="0">无</el-radio-button>
          </el-radio-group>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <el-tooltip content="添加类型" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-plus"
              @click="addSon"
              circle
            ></el-button>
          </el-tooltip>
          <!-- <el-tooltip content="列表刷新" placement="top" effect="light">
            <el-button
              class="editor"
              size="mini"
              icon="el-icon-refresh-right"
              @click="searchTapReset()"
              circle
            ></el-button>
          </el-tooltip> -->
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x: auto">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            size="mini"
            :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}"
            tooltip-effect="dark"
            style="width: 100%; overflow: auto"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          >
            <el-table-column label="用户名称" width="100">
              <template #default="scope">{{
                scope.row.nickName == null ? "-" : scope.row.nickName
              }}</template>
            </el-table-column>
            <el-table-column label="用户ID" width="100">
              <template #default="scope">{{
                scope.row.uid == null ? "-" : scope.row.uid
              }}</template>
            </el-table-column>
            <el-table-column label="类型"  width="180">
              <template #default="scope">{{
                scope.row.name == null ? "-" : scope.row.name
              }}</template>
            </el-table-column>
            <el-table-column label="关键字" width="450">
              <template #default="scope">
                <div class="showKeywords">
                  {{ scope.row.keywords == null ? "-" : scope.row.keywords }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="默认规则">
              <template #default="scope">
                {{
                  scope.row.isDefault === null ? "-" : scope.row.isDefaultText
                }}
              </template>
            </el-table-column>
            <el-table-column >
              <template #header>
                <div class="question">
                  <span>级别</span>
                  <el-tooltip
                    effect="light"
                    placement="bottom"
                  >
                    <template #content>
                      <div  class="questionTip">质检的风险等级，根据用户需要可以设置为：高、中、低、无四种。</div>
                    </template>
                    <div class="questionIcon">?</div>
                  </el-tooltip>
                </div>
              </template>
              <template #default="scope">
                <span>{{
                  scope.row.riskText === null ? "-" : scope.row.riskText
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template #default="scope">
                {{ 
                  scope.row.status === null ? "-" : scope.row.statusText 
                }}
              </template>
            </el-table-column>
            <el-table-column>
              <template #header>
                <div class="question">
                  <span>优先级</span>
                  <el-tooltip
                    effect="light"
                    placement="bottom"
                  >
                    <template #content>
                      <div class="questionTip">当一条录音开始质检时,按照质检类型优先级进行质检,优先级按照阿拉伯数字顺序从1开始。</div>
                    </template>
                    <div class="questionIcon">?</div>
                  </el-tooltip>
                </div>
              </template>
              <template #default="scope">
                {{
                  scope.row.pri === null ? "-" : scope.row.pri
                }}
              </template>
            </el-table-column>
            <el-table-column label="创建/更新" width="240px">
              <template #default="scope">
                <div class="grid">
                  <p>创建：{{ scope.row.createTime === null ? "-" : scope.row.createTime }}</p>
                  <p>更新：{{ scope.row.updateTime === null ? "-" : scope.row.updateTime }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template #default="scope">
                <el-tooltip content="类型编辑" placement="top" effect="light">
                  <el-button
                    class="editor"
                    size="mini"
                    icon="el-icon-edit"
                    @click="typeModify(scope.row)"
                    round
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="类型删除" placement="top" effect="light">
                  <el-button
                    class="deletetable"
                    size="mini"
                    icon="el-icon-delete"
                    round
                    @click="deleteList(scope.row.id)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗→添加类型 -->
    <div class="pop-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="add_to_form"
        :rules="rules"
        ref="add_to_form"
      >
        <el-dialog
          :title="addOrModify===1?'添加类型':'修改类型'"
          v-model="centerAddtoVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="50%"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="5">
                <el-form-item label="类型名称：" prop="name">
                  <el-input v-model="add_to_form.name" maxlength="10" placeholder="请输入类型名称"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="5">
                <el-form-item label="默认规则：" prop="">
                  <el-select
                    v-model="add_to_form.isDefault"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['非默认', '默认']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="5">
                <el-form-item label="状&emsp;态&emsp;:" prop="status">
                  <el-select
                    v-model="add_to_form.status"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['启用', '禁用']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="优先级：" prop="pri">
                  <!-- <el-input v-model="add_to_form.pri" placeholder="请输入" @input="priValidate($event)"></el-input> -->
                  <el-input v-model="add_to_form.pri" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="风险等级：" prop="risk">
                  <el-select
                    v-model="add_to_form.risk"
                    placeholder="未选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['无', '低', '中', '高']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="命中后不再继续质检：" prop="pass">
                  <el-select
                    v-model="add_to_form.pass"
                    placeholder="未选择"
                    style="width: 60%"
                    clearable
                  >
                    <el-option
                      v-for="item in [{label:'是',value:true},{label:'否',value:false}]"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="12">
              <el-col>
                <el-form-item label="关键字串：" prop="keywords">
                  <el-input
                    type="textarea" 
                    v-model="add_to_form.keywords"
                    @input="keywordsValidate($event)"
                    :rows="8"
                    placeholder="请输入内容"
                    clearable>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="centerAddtoVisible = false"
                round
                >取消</el-button
              >
              <el-button class="confirmAdd" @click="typeAddFun()" round
                >{{addOrModify===1?'添加':'修改'}}</el-button
              >
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>
import voice from "@/api/open/voice/voiceType";
import { message } from 'ant-design-vue';
import { IconFont } from "@/utils/iconfont";
import { pInteger } from "@/utils/validate";
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
export default {
  name: "ControllerType",
  components: {
    IconFont
  },
  data() {
    return {
      tableData: [], //当前页面数据
      activeNames: [], //折叠面板
      centerAddtoVisible: false, // 新增/修改弹框
      addOrModify:null,  // 判断弹框状态 (1:添加 2:修改)
      loading: true, // loding 状态启用
      tableHeight: "", //表格高度
      // operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      valueTimeCreate: [], //选中时间段
      searchForm: {    // 搜索项
        name:'',       // 类型名称
        isDefault:'',  // 默认选项(0:非默认 1:默认)
        status:'',     // 状态(0:启用 1:禁用)
        keywords:'',   // 关键字
        risk:'',  //风险等级(0-无/1-低/2-中/3-高)
        pageSize:10,
        page:1
      },
      add_to_form:{    // 添加列表项--表单
        name:'',       // 类型名称
        isDefault:'',  // 默认选项(0:非默认 1:默认)
        status:'',     // 状态(0:启用 1:禁用)
        keywords:'',   // 关键字
        risk:'',       //风险等级(0-无/1-低/2-中/3-高)
        pass:'',       //命中后是否跳过(0-否/1-是)
        pri:'',        //优先级
      },
      rules:{ //表单校验
        name: [
          { required: true, message: '请输入类型名称', trigger: 'blur' },
          { max: 10, message: '请输入正确的类型名称', trigger: 'blur' },
          { validator: function(rule, value, callback) {//中文
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入正确的类型名称"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        status: [
          {required: true, message: '请选择状态', trigger: 'blur'}
        ],
        pri: [
          {required: true, message: '请输入优先级', trigger: 'blur'},
          {validator: (rule,value,callback)=>{
            if(pInteger(value) == false) {
              callback(new Error("请输入正确的质检优先级数值"));
            } else {
              callback()
            }
          }}
        ],
        risk: [
          {required: true, message: '请选择风险等级', trigger: 'blur'}
        ],
        pass: [
          {required: true, message: '请选择是否继续执行', trigger: 'blur'}
        ],
      }
    };
  },
  methods: {
    // 类型删除
    deleteList(id){
      ElMessageBox.confirm(
        '你确定要删除该条记录?',
        '注意',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          voice.voiceDelete({id}).then(res=>{
            if(res.code===200){
              ElMessage({
                type: 'success',
                message: '删除成功',
              })
              this.queryList();
            }
          })
          
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 类型修改--点击
    typeModify(val){
      this.addOrModify = 2;
      this.add_to_form = val;
      this.centerAddtoVisible = true;
    },
    // 条件筛选--重置
    searchTapReset() {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      };
      this.queryList();
    },
    // 条件筛选--搜索
    searchListFun() {
      this.queryList();
    },
    // 添加子项--确认
    typeAddFun(){
      this.$refs.add_to_form.validate(valid=>{
        if(valid) {
          if(this.addOrModify===1){
            voice.voiceAdd({...this.add_to_form}).then(res=>{
              if(res.code===200){
                message.success('添加成功');
                this.centerAddtoVisible = false;
                this.queryList();
                this.$refs.add_to_form.resetFields()
              }
            })
          }else{
            voice.voiceEdit({...this.add_to_form}).then(res=>{
              if(res.code===200){
                message.success('修改成功');
                this.centerAddtoVisible = false;
                this.queryList();
                this.$refs.add_to_form.resetFields()
              }
            })
          }
        }else {
          return false
        }
      })
    },
    // 分页--条数
    handleSizeChange(val) {
        this.searchForm.pageSize = val;
        this.queryList()
    },
    // 分页--页码
    handleCurrentChange(val) {
        this.searchForm.page = val;
        this.currentPage = val;
        this.queryList()
    },
    // 添加子项--点击
    addSon() {
      this.addOrModify = 1;
      this.add_to_form = {
        name:'',       // 类型名称
        isDefault:'',  // 默认选项(0:非默认 1:默认)
        status:'',     // 状态(0:启用 1:禁用)
        keywords:'',   // 关键字
        risk:'',       //风险等级(0-无/1-低/2-中/3-高)
        pass:'',       //命中后是否跳过(0-否/1-是)
        pri:'',        //优先级
      };
      this.centerAddtoVisible = true;
    },
    //添加子项-优先级正整数
    priValidate(val){
      this.add_to_form.pri = pInteger(val) ? parseInt(val) : 1
    },
    //添加子项-关键字串
    keywordsValidate(val){
      const regexp = /[\s]*[,|\uff0c][\s]*[,|\uff0c]{1,}([\s]+|[,|\uff0c]+)*/ig;
      const regexp2 = /[\s]*\uff0c[\s]*/ig;
      this.add_to_form.keywords = val.replace(regexp2,',').replace(regexp,',')
    },
    //条件筛选--风险等级
    riskChange() {
      console.log(this.searchForm.risk)
    },
    // 查询数据列表
    queryList() {
      this.loading = true;
      voice.voiceList({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.queryList();
  },
};
</script>
<style lang="scss" scoped>
.controller-type{
  padding-right: .9375rem;
}
.title-buttom{
  height: 40px;
}
// .risk-button ::v-deep(.el-radio-button__inner) {
//   width: 80px;
// }
::v-deep(.risk-button .el-radio-button__inner){
  width: 80px;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.showKeywords{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.question {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .questionIcon {
  width: 1rem;
  height: 1rem;
  margin-left: .1875rem;
  text-align: center;
  line-height: .875rem;
  background: #4f5aeb;
  border: 1px solid #ffffff;
  border-radius: 50%;
  font-size: .5625rem;
  color: #ffffff;
  }
}
.questionTip {
  width: 9.375rem;
  font-size: .875rem;
}
</style>